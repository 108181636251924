import * as grandVisionApi from '../../api/grandVisionApi';
import get from 'lodash/get';
import * as R from 'ramda';
import axios from 'axios';
import { trackAppointmentCancellation, trackThankYou } from './trackingActions';
import { customAlphabet } from 'nanoid';
export const GET_APPOINTMENT = 'GET_APPOINTMENT';
export const CANCEL_APPOINTMENT = 'CANCEL_APPOINTMENT';
export const CREATE_CUSTOMER = 'CREATE_CUSTOMER';
export const CREATE_CUSTOMER_FAILURE = 'CREATE_CUSTOMER_FAILURE';
export const CREATE_APPOINTMENT = 'CREATE_APPOINTMENT';
export const CREATE_APPOINTMENT_FAILURE = 'CREATE_APPOINTMENT_FAILURE';
export const ADD_APPOINTMENT_STATUS = 'ADD_APPOINTMENT_STATUS';
export const GET_APPOINTMENT_LOGIN = 'GET_APPOINTMENT_LOGIN';
export const GET_APPOINTMENT_FAILURE = 'GET_APPOINTMENT_FAILURE';
export const GET_APPOINTMENT_LOGIN_FAILURE = 'GET_APPOINTMENT_LOGIN_FAILURE';
export const GET_APPOINTMENT_LOGIN_EXCEPTION = 'GET_APPOINTMENT_LOGIN_EXCEPTION';
export const CLEAR_DATA_APPOINTMENT = 'CLEAR_DATA_APPOINTMENT';

/**
 * Create reservation
 * Create customer, appointment and then poll appointment to see if it succeeded
 * @param {object} values         User filled in values
 * @param {object} history        History object for redirecting
 */
export const createReservation = (values, history, lang) => async (dispatch, getState) => {
  const appointmentType = R.path(['availability', 'appointmentType'], getState());
  const selectedStoreID = R.path(['availability', 'selectedStoreId'], getState());
  const selectedOptician = R.path(['availability', 'selectedOptician'], getState());
  const startTime = R.path(['availability', 'timeSlot'], getState());
  const endTime = R.path(['availability', 'endTime'], getState());
  const preferredChannelOfCommunication = values.preferredChannelOfCommunication;
  const personalId = values.personalID;
  const customerEmail = R.path(['email'], values);
  const customerPhoneNumber = R.path(['phoneNumber'], values);
  const blockedEmails = R.pathOr(
    [],
    ['contentful', 'layout', 'bookingBlocklist', 'fields', 'blockedEmails'],
    getState(),
  );
  const blockedPhoneNumbers = R.pathOr(
    [],
    ['contentful', 'layout', 'bookingBlocklist', 'fields', 'blockedPhoneNumbers'],
    getState(),
  );

  dispatch({
    type: ADD_APPOINTMENT_STATUS,
    status: 'PENDING',
  });

  history.push(`/${lang}/confirmed`);

  if (blockedPhoneNumbers.indexOf(customerPhoneNumber) > -1 || blockedEmails.indexOf(customerEmail) > -1) {
    const userIPRes = await axios.get('https://api.ipify.org/?format=json');
    const timeStamp = new Date();

    grandVisionApi
      .logBookingStatus(R.path(['data', 'ip'], userIPRes), customerEmail, customerPhoneNumber, timeStamp)
      .then(results => {
        Promise.all([
          dispatch({
            type: ADD_APPOINTMENT_STATUS,
            status: 'DENIED',
          }),
        ]);
      })
      .catch(error =>
        Promise.all([
          dispatch({
            type: `${GET_APPOINTMENT}_FAILURE`,
            error,
          }),
        ]),
      );

    return false;
  }

  // Form date of birth
  let yearStart = personalId.substring(6, 7) === 'A' ? '20' : '19';

  const dateOfBirth = `${yearStart}${personalId.substring(4, 6)}-${personalId.substring(2, 4)}-${personalId.substring(
    0,
    2,
  )}`;

  // Create short booking reference ID
  const nanoid = customAlphabet('123456789AEHKLMNPRSTUVY', 6);
  const shortBookingRef = nanoid();

  grandVisionApi
    .createCustomer(values, dateOfBirth)
    .then(results => {
      console.log('CREATE CUSTOMER', results);
      Promise.all([
        dispatch({
          type: CREATE_CUSTOMER,
          results,
        }),
      ]);
      grandVisionApi
        .createAppointment(
          values,
          results,
          appointmentType,
          selectedStoreID,
          selectedOptician,
          startTime,
          endTime,
          shortBookingRef,
          preferredChannelOfCommunication,
          lang,
          dateOfBirth,
        )
        .then(results => {
          console.log('CREATE APPOINTMENT', results);
          Promise.all([
            dispatch({
              type: CREATE_APPOINTMENT,
              results,
            }),
          ]);
          grandVisionApi
            .getAppointmentDuringCreation(results)
            .then(results => {
              console.log('POLL APPOINTMENT', results);
              const appointmentStatus = get(results, ['data', 'attributes', 'status']);

              Promise.all([
                dispatch({
                  type: GET_APPOINTMENT,
                  results,
                }),
                dispatch(trackThankYou(appointmentStatus)),
              ]);
            })
            .catch(error =>
              Promise.all([
                dispatch({
                  type: `${GET_APPOINTMENT}_FAILURE`,
                  error,
                }),
              ]),
            );
        })
        .catch(error =>
          Promise.all([
            dispatch({
              type: `${CREATE_APPOINTMENT}_FAILURE`,
              error,
            }),
          ]),
        );
    })
    .catch(error =>
      Promise.all([
        dispatch({
          type: `${CREATE_CUSTOMER}_FAILURE`,
          error,
        }),
      ]),
    );
};

/**
 * Get appointment by sending a request to GrandVision API
 * @param {object} values         User filled in values
 * @param {object} history        History object for redirecting
 */
export const getAppointmentLogin = (values, history, lang) => dispatch => {
  console.log('GET APPOINTMENT ACTION');

  grandVisionApi
    .getAppointmentDuringLogin(values)
    .then(results => {
      // Appointment found
      if (R.path(['reservation'], results)) {
        Promise.all([
          dispatch({
            type: GET_APPOINTMENT_LOGIN,
            results,
          }),
        ]);

        history.push(`/${lang}/appointment/my-appointments`);
      } else {
        Promise.all([
          dispatch({
            type: `${GET_APPOINTMENT_LOGIN}_EXCEPTION`,
          }),
        ]);
      }
    })
    .catch(error => {
      console.log('get appointment failure');
      Promise.all([
        dispatch({
          type: `${GET_APPOINTMENT_LOGIN}_FAILURE`,
          error,
        }),
      ]);
    });
};

/**
 * Cancel appointment by sending a request to GrandVision API
 * @param {object} values         User filled in values
 * @param {object} history        History object for redirecting
 * @param {string} lang           Language
 */
export const cancelAppointment = (values, history, lang) => (dispatch, getState) => {
  const appointmentUUID = R.path(['appointment', 'reservationDetails', 'appointmentUUID'], getState());
  grandVisionApi
    .cancelAppointment(values)
    .then(() => {
      Promise.all([
        dispatch({
          type: CANCEL_APPOINTMENT,
        }),
      ]);

      dispatch(trackAppointmentCancellation(appointmentUUID));
      history.push(`/${lang}/appointment/cancelled`);
    })
    .catch(error =>
      Promise.all([
        dispatch({
          type: `${CANCEL_APPOINTMENT}_FAILURE`,
          error,
        }),
      ]),
    );
};

/**
 * Clear user filled data
 */
export const clearDataAppointment = () => dispatch => {
  dispatch({
    type: CLEAR_DATA_APPOINTMENT,
  });
};
